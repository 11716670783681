import React, { Fragment, useState } from "react";

import { navigate } from "@reach/router"

import { AddCircleOutline, Edit, EventNote } from "@material-ui/icons";
import { Box, Chip, createStyles, Divider, Fab, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from "@material-ui/core";
import Session from "data/session";
import EditSessionDialog from "./edit_dialog";

import { auth, db } from "fb"
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore"

import CreateSessions from "./create";
import HostIcon from "components/HostIcon";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    }
  }),
);

export default function SesssionList(props: any) {
  const [user] = useAuthState(auth);
  const [sessions, sessions_loading, err] = useCollection(db.collection("sessions").where("members", "array-contains", user.email))

  const classes = useStyles()
  if (err) {
    console.error(err)
    // debugger
  }
  if (sessions_loading) return <LinearProgress />

  return <Box p={1} maxWidth={1280} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
    <h1 style={{ textAlign: 'center' }}>Sessions <CreateSessions /></h1>
    <List>
      {sessions?.docs.map((doc: any) => {
        const session = new Session(doc, user)

        return <Fragment key={session.id}>
          <ListItem>
            {session.host && <ListItemIcon> <HostIcon /></ListItemIcon>}
            <ListItemText primary={<>
              {session.title}
              {session.date && <Chip style={{ marginLeft: '1em' }} size="small" icon={<EventNote />} label={session.date_distance} />}
            </>
            } secondary={
              session.date && ``
            } />

            <ListItemSecondaryAction>
              {session.host &&
                <EditSessionButton session={session} />
              }
              <Fab onClick={() => navigate(`/j/${session.id}`)}
                variant="extended"
                className={`${classes.margin}`}
                color="secondary" aria-label="Join Session"
                size="medium">
                <AddCircleOutline className={classes.extendedIcon} /> Join
                </Fab>
            </ListItemSecondaryAction>

          </ListItem>
          <Divider variant="inset" component="li" />

        </Fragment>
      })}
    </List >
  </Box>
}

function EditSessionButton(props: any) {
  const { session } = props;
  const [open, setOpen] = useState(false)

  return <IconButton onClick={() => setOpen(true)} edge="end" aria-label="Edit">
    <EditSessionDialog update={session} open={open} onClose={() => setOpen(false)} />
    <Edit />
  </IconButton>
}