/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from "react";
import { useSession } from "../context";
import { StarOutline } from "@material-ui/icons";
import { useDeviceId } from "util/id";
import { useMediaDeviceContext } from "media_device/context";
import SMPeerConnection, { destroySMPC, getSMPC, getSMPCByKey } from "smoothepeerconnection";
import { useUser } from "user_context";
import { MemberDevices } from "data/session";
import { usePerformanceContext } from "performance_context";
import { Grid } from "@material-ui/core";
import { MediaStatusCode } from "media_device";


interface MemberStreamProps {
  peerUID: string
  peerDeviceId: string
  peerDevices: MemberDevices
  send_only?: boolean
  key?: any
}
export function MemberStream(props: MemberStreamProps) {
  const { peerUID, peerDeviceId, peerDevices, send_only = false } = props
  const user = useUser()
  const primaryDevice = (peerDeviceId === peerDevices.primaryDevice)
  const session = useSession()
  const myDeviceId = useDeviceId()
  const [state, setState] = useState('')
  const [{ status: deviceStatus, stream, trackCount, sinkId }] = useMediaDeviceContext()
  const { timestamp } = usePerformanceContext()
  const { smpcKey, SMPC } = getSMPC({
    localStream: stream,
    session, myUID: user.uid, myDeviceId, peerUID, peerDeviceId,
    send_only,
    // rec_only: !primaryDevice,
    rec_only: false,
    timestamp,
    stateSetter: setState
  })

  const [video, setVideo] = useState<HTMLVideoElement>()
  const [videoState, setVideoState] = useState('')

  // window['localStream' as any] = stream as any

  useEffect(() => {
    if (video) {
      const _smpc = getSMPCByKey(smpcKey)!
      if (!_smpc) window.location.reload()
      _smpc.setVideoElem(video)
      window['v' as any] = video as any
    }
  }, [smpcKey, video])

  useEffect(() => {
    const _smpc = getSMPCByKey(smpcKey)!
    _smpc && _smpc.updateStateSetter(setState)
  }, [setState, smpcKey])


  useEffect(() => {
    // const _smpc = getSMPCByKey(smpcKey)!
    // _smpc && _smpc.newPC('Let us begin!')
    return () => {
      destroySMPC(smpcKey)
    }
  }, [smpcKey, stream])

  useEffect(() => {
    const _smpc = getSMPCByKey(smpcKey)!
    _smpc && _smpc.updateLocalTracks(stream)
  }, [trackCount, smpcKey, stream])


  useEffect(() => {
    if (video) {
      const events = [
        'canplay',
        'canplaythrough',
        'complete',
        'ended',
        'pause',
        'play',
        'playing',
        'pause',
        'stalled',
        'suspend',
        'waiting',
      ]
      events.forEach((ev: any) => {
        video.addEventListener(ev, () => setVideoState(ev))
      })
    }
  }, [video, setVideoState])

  useEffect(() => {
    if (sinkId && video && 'setSinkId' in video) {
      (video as any).setSinkId(sinkId)
    }
  }, [sinkId, video])

  if (send_only) {
    return <span>This device sends only</span>
  }

  const display = ['playing', 'canplaythrough', 'paused'].includes(videoState)
  return <Grid item sm>
    {!display && <>{`videoState: ${videoState} state:${state}`}</>}
    <video
      ref={setVideo as any}
      style={{
        // display: display ? 'block' : 'none',
        maxHeight: '33vh'
      }}
      id="outvid" width="100%"
      autoPlay playsInline
      controls
      muted={deviceStatus !== MediaStatusCode.SUCCESS}
    />
  </Grid>
  // <div style={{ display: 'inline' }}>
  //   <h5>{primaryDevice && <StarOutline />}{peerDeviceId} {send_only && <>Send Only</>}</h5>
  //   <h4>status: {status}</h4>
  // </div>
}
