import { createContext, useContext } from "react";


export class Performance {
  start = performance.now()
  timestamp: Function
  constructor() {
    this.timestamp = this._timestamp.bind(this)
  }

  _timestamp() {
    return Math.floor(performance.now() - this.start)
  }
}

export const PerformanceContext = createContext<Performance>(new Performance());

export function usePerformanceContext() {
  return useContext(PerformanceContext)
}
