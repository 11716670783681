import { v4 as uuid } from 'uuid';
import createPersistedState from 'use-persisted-state';
import { getSessionStorage } from './get-provider';
import { useEffect } from 'react';

const useSessionIdState = createPersistedState('smoothe-session-id', getSessionStorage());
const useDeviceIdState = createPersistedState('smoothe-device-id');

const _new_session_id = uuid()

export { uuid }
export function useSessionId() {
  const [session_id, setSessionId] = useSessionIdState('')

  useEffect(() => {
    if (!session_id) {
      setSessionId(_new_session_id)
    }
  }, [session_id, setSessionId])
  return session_id || _new_session_id
}

export function useDeviceId() {
  const [session_id, setSessionId] = useDeviceIdState('')

  useEffect(() => {
    if (!session_id) {
      setSessionId(_new_session_id)
    }
  }, [session_id, setSessionId])
  return session_id || _new_session_id
}

