import { BottomNavigation, BottomNavigationAction, Button, ButtonGroup, ClickAwayListener, createStyles, Divider, Grow, ListItemIcon, makeStyles, MenuItem, MenuList, Paper, Popper, Theme, Typography } from "@material-ui/core"
import { ArrowDropUp, ErrorOutline, Mic, MicOff, VideoCall, Videocam, VideocamOff, VolumeUp } from "@material-ui/icons"
import LocalVid from "components/local_video";
import { MediaStatusCode } from "media_device";
import { useRef, useState } from "react";
import { useMediaDeviceContext } from "./context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomActions: {
      position: 'fixed',
      bottom: 0,
      width: '100%'
    },
  }),
);

export default function MediaControls() {
  const classes = useStyles()
  const [state, { startMedia, stopVideo, startVideo, stopAudio, startAudio, setDevice }] = useMediaDeviceContext()

  const { status, audioEnabled, videoEnabled, devices, device_constraints, sinkId } = state

  const anchorRef = useRef<HTMLDivElement>(null);

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  return <BottomNavigation className={classes.bottomActions}>
    {status === MediaStatusCode.NO_PERMISSION &&
      <BottomNavigationAction showLabel
        onClick={startMedia}
        label='Permission Denied' icon={<ErrorOutline />} />
    }
    {status === MediaStatusCode.INIT &&
      <BottomNavigationAction showLabel onClick={startMedia} label="Use Audio &amp; Video" icon={<VideoCall />} />
    }

    {/* We're doing this stupidity because BottomNavigation doesn't like fragment children */}
    {status === MediaStatusCode.SUCCESS && [
      <ButtonGroup key="buttons" ref={anchorRef} >
        {videoEnabled && <Button size="medium" variant="text" onClick={stopVideo} startIcon={<VideocamOff />}>Stop Video</Button>}
        {!videoEnabled && <Button size="medium" variant="text" onClick={startVideo} startIcon={<Videocam />}>Start Video</Button>}
        {audioEnabled && <Button size="medium" variant="text" onClick={stopAudio} startIcon={<MicOff />}>Stop Audio</Button>}
        {!audioEnabled && <Button size="medium" variant="text" onClick={startAudio} startIcon={<Mic />}>Start Audio</Button>}
        <Button size="medium" variant="text"
          aria-controls={openMenu ? 'split-button-menu' : undefined}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => setOpenMenu(v => !v)}
        >
          <ArrowDropUp />
        </Button>
      </ButtonGroup>
      ,
      <Popper key="menu" open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <MenuList id="split-button-menu">
                {devices.filter(d => d.kind === "audiooutput").map((d) => {
                  const cur_dev_id = sinkId || "default"
                  const selected = d.deviceId === cur_dev_id
                  return <MenuItem
                    key={d.deviceId}
                    selected={selected}
                    // disabled={index === 2}
                    // selected={index === selectedIndex}
                    onClick={() => setDevice(d)}
                  >
                    <ListItemIcon>
                      {selected && <VolumeUp />}
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {d.label}
                    </Typography>
                  </MenuItem>
                })}
                <Divider />
                {devices.filter(d => d.kind === "audioinput").map((d) => {
                  const cur_dev_id = (device_constraints.audio as any).deviceId || 'default'
                  const selected = d.deviceId === cur_dev_id
                  return <MenuItem
                    key={d.deviceId}
                    selected={selected}
                    // disabled={index === 2}
                    // selected={index === selectedIndex}
                    onClick={() => setDevice(d)}
                  >
                    <ListItemIcon>
                      {selected && <Mic />}
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {d.label}
                    </Typography>
                  </MenuItem>
                }
                )}
                <Divider />
                {devices.filter(d => d.kind === "videoinput").map((d) => {
                  const cur_dev_id = (device_constraints.video as any).deviceId || 'default'
                  const selected = d.deviceId === cur_dev_id
                  return <MenuItem
                    key={d.deviceId}
                    selected={selected}
                    // disabled={index === 2}
                    // selected={index === selectedIndex}
                    onClick={() => setDevice(d)}
                  >
                    <ListItemIcon>
                      {selected && <Videocam />}
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {d.label}
                    </Typography>
                  </MenuItem>
                }
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
        }
      </Popper>
    ]

    }
    <LocalVid />
  </BottomNavigation >
}