export function getSessionStorage() {
  if (typeof global !== 'undefined' && global.sessionStorage) {
    return global.sessionStorage;
  }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined' && globalThis.sessionStorage) {
    // eslint-disable-next-line no-undef
    return globalThis.sessionStorage;
  }
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage;
  }
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage;
  }
  throw new Error("No sessionStorage")
};