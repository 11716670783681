import { Alert } from "@material-ui/lab"
import { MediaStatusCode } from "media_device";
import { useMediaDeviceContext } from "media_device/context";
import { useEffect, useRef } from "react"


export default function LocalVid() {
  const localVideo = useRef<HTMLVideoElement>(null as unknown as HTMLVideoElement)
  const [{ stream, trackCount, status, videoEnabled }] = useMediaDeviceContext()

  // const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (localVideo.current && trackCount > 0) {
      localVideo.current.srcObject = stream
    }
  }, [stream, localVideo, trackCount])

  // useEffect(() => {
  //   const vid = localVideo.current
  //   if (vid) {
  //     const events = [
  //       'canplay',
  //       'canplaythrough',
  //       'complete',
  //       'ended',
  //       'pause',
  //       'play',
  //       'playing',
  //       'pause',
  //       // 'progress',
  //       'stalled',
  //       'suspend',
  //       'waiting',
  //     ]
  //     events.forEach((ev: any) => {
  //       vid.addEventListener(ev, () => console.log(`LOCALVID: ${ev}`))
  //     })
  //   }
  // }, [localVideo])


  if (status === MediaStatusCode.NO_PERMISSION)
    return <Alert severity="warning">No permission for Audio &amp; Camera</Alert>

  // return <Box display="flex" >
  return <video
    style={{
      display: videoEnabled ? 'block' : 'none',
      position: 'absolute', right: 0
    }}
    width={64} height="100%" ref={localVideo} muted autoPlay playsInline
  />
  // {/* {trackCount ?
  //   <Button onClick={() => stopMedia()}>Stop Video</Button>
  //   :
  //   <Button onClick={() => startMedia()}>Start Video</Button>
  // } */}
  // {/* <ul>
  //   {stream && stream.getTracks().map((t: any) => <li key={t.id}>{t.kind}: {t.enabled ? 'live' : 'disabled'} </li>)}
  // </ul>
  // <ul>
  //   {devices.filter(d => d.kind === 'audioinput').map(d => <li key={d.deviceId}>{d.kind} ({d.deviceId}): {d.label}</li>)}
  // </ul>
  // <ul>
  //   {devices.filter(d => d.kind === 'videoinput').map(d => <li key={d.deviceId}>{d.kind} ({d.deviceId}): {d.label}</li>)}
  // </ul> */}
  // </Box>
}