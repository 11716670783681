import Session, { RecordingState } from "data/session";
import { MediaState } from "media_device";
// /* eslint-disable import/no-webpack-loader-syntax */
// import Worker from 'worker-loader!./worker';
import RecordUploaderWorker from './worker'
import { Progress } from "./worker/worker";
import { proxy } from 'comlink'

export default class Recorder {
  session_recorders: Map<string, SessionRecorder>

  constructor() {
    this.session_recorders = new Map()
  }

  update(session: Session, media: MediaState, userId: string, deviceId: string) {
    let sr = this.session_recorders.get(session.id)
    if (!sr) {
      sr = new SessionRecorder(session, media, userId, deviceId)
      this.session_recorders.set(session.id, sr)
    } else {
      sr.update(session, media)
    }
  }
}

class SessionRecorder {
  session!: Session //We call update_session in constructor
  userId: string
  deviceId: string
  recorder: MediaRecorder
  workers: Array<any>

  constructor(session: Session, media: MediaState, userId: string, deviceId: string) {
    this.userId = userId
    this.deviceId = deviceId
    // try {
    //   this.recorder = new MediaRecorder(media.stream, { mimeType: 'video/webm;codecs=H264' })
    // } catch {
    const mimeType = `video/webm;codecs=h264`
    console.log('isTypeSupported', mimeType, MediaRecorder.isTypeSupported(mimeType))
    try {
      this.recorder = new MediaRecorder(media.stream, { mimeType })
    } catch {
      this.recorder = new MediaRecorder(media.stream)
    }
    // }
    this.workers = []
    const r = this.recorder as any

    r.id = Math.random().toString().substr(2, 4)

    this.update(session, media)
    window['recorder' as any] = this as any
  }

  progress(progress: Progress) {
    console.log("zomgozmgzomg progress", progress)
  }

  update(session: Session, media: MediaState) {
    this.session = session
    // console.log("update sesssss", session.recording)
    // console.log((this.recorder as any).id, this.recorder.state)
    switch (session.recording) {
      case RecordingState.RECORDING:
        if (this.recorder.state !== "recording") {
          if (media.trackCount > 0) {
            let keep_trying = true
            while (keep_trying) {
              try {
                const worker = new RecordUploaderWorker()
                worker.init(this.session.id, this.userId, this.deviceId, proxy(this.progress))
                this.workers.push(worker)
                this.register_recorder_events(worker)
                this.recorder.start(5000)
                keep_trying = false
              } catch (e) {
                console.error(e)
                keep_trying = window.confirm("Error starting recorder, keep trying?")
              }
            }
          }
        }
        break;
      case RecordingState.STOPPED:
        if (this.recorder.state === "recording") {
          // console.log('[Recorder] STOP')
          // this.recorder.requestData()
          this.recorder.stop()
        }
        break;

    }
  }

  register_recorder_events(worker: RecordUploaderWorker) {
    this.recorder.ondataavailable = async (event: BlobEvent) => {
      if (event.data.size > 0) {
        console.log('adding chunk', event.data.size)
        worker.push(await event.data.arrayBuffer());
      }
    }

    this.recorder.onstart = async () => worker.onstart(Date.now())

    this.recorder.onstop = async () => {
      const progress = await worker.onstop()
      console.log(progress)
      // if (chunks.byteLength === 0) {
      //   console.log("NOTHING TO SEE HERE!")
      //   return
      // }

      // var blob = new Blob([chunks]);
      // var url = URL.createObjectURL(blob);
      // var a = document.createElement('a');
      // document.body.appendChild(a);
      // a.href = url;
      // a.download = `${this.session.title}.mp4`;
      // a.click();
      // window.URL.revokeObjectURL(url);
    }

    // this.recorder.ondataavailable = (event: BlobEvent) => {
    //   console.log('adding chunk', event.data.size)
    //   if (event.data.size > 0) {
    //     this.chunks.push(event.data);
    //   }
    // }

    // this.recorder.onstart = () => {
    //   this.chunks = []
    //   console.log((this.recorder as any).id, "[Recorder] START")
    // }

    // this.recorder.onstop = () => {
    //   console.log((this.recorder as any).id, "[Recorder] STOP")
    //   if (this.chunks.length === 0) return
    //   var blob = new Blob(this.chunks);
    //   var url = URL.createObjectURL(blob);
    //   var a = document.createElement('a');
    //   document.body.appendChild(a);
    //   a.href = url;
    //   a.download = `${this.session.title}.mp4`;
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // }
  }
}