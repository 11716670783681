import 'firebase/auth'
import 'firebase/firestore'

import firebase from 'firebase/app'
import { app } from './app'

export const {
  Timestamp, FieldPath, FieldValue,
} = firebase.firestore


// debugger

export const db = app.firestore()

export const auth = app.auth()


if (window && ["localhost", "nmbpr.local"].includes(window.location.hostname)) {
  db.useEmulator(window.location.hostname, 8080);
  // auth.useEmulator(`${window.location.protocol}//${window.location.hostname}:9099`);
}


export function login() {
  auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
}

export function serverTimestamp() {
  return FieldValue.serverTimestamp()
}

export function firestoreTimestampfromDateString(dateStr?: string) {
  return dateStr ? Timestamp.fromDate(new Date(dateStr)) : null
}



export { firebase }