import { Box, Container, LinearProgress, ListItemIcon, Paper, Typography } from "@material-ui/core"

import Session from "data/session"

import { db, FieldPath } from "fb"
import { useEffect, useMemo } from "react"

import { useDocument } from "react-firebase-hooks/firestore"
import { useUser } from "user_context"
import SessionControls from "./controls"
import { SessionContext } from "./context"

import firebase from 'firebase/app'
import useMediaDevices from "media_device"
import { MediaDeviceContext } from "media_device/context"

import './session.css'
import MediaControls from "media_device/controls"
import OtherStreams from "./streams/other_streams"
import { useRecorderContext } from "recorder/context"
import HostIcon from "components/HostIcon"
import { useDeviceId } from "util/id"


export default function SessionView(props: any) {
  const { sessionId } = props
  const media = useMediaDevices()
  const user = useUser()
  const deviceId = useDeviceId()
  const sessionRef = db.doc(`/sessions/${sessionId}`)
  const [sessionDoc, loading, error] = useDocument(sessionRef)

  const _session = useMemo<Session>(() => sessionDoc && new Session(sessionDoc, user), [sessionDoc, user])
  const recorder = useRecorderContext()

  const media_state = media[0]

  useEffect(() => {
    if (_session) {
      recorder.update(_session, media_state, user.uid, deviceId)
    }
  }, [_session, recorder, media_state, user.uid, deviceId])

  useEffect(() => {
    if (!sessionDoc) return
    const uidFieldPath = new FieldPath('member_info', user.email!, 'uid')
    const uid = (sessionDoc as firebase.firestore.QueryDocumentSnapshot).get(uidFieldPath)
    if (sessionDoc && !uid) {
      sessionRef.update(uidFieldPath, user.uid)
    }
  }, [sessionDoc, sessionRef, user.email, user.uid])

  if (loading) return <LinearProgress />

  if (!sessionDoc || !sessionDoc.exists || error) {
    return <Container maxWidth="sm">
      <Box display="flex" alignItems="center" justifyContent="center" justifyItems="center">
        <Paper style={{ maxWidth: '75ch' }}>
          <Typography align="center" color="error" variant="h2">No bueno!</Typography>
          <Typography color="textPrimary" variant="body1">
            Please check to make sure your e-mail address ({user.email}) has been invited.
      </Typography>
        </Paper>
      </Box>
    </Container>
  }

  const session = _session!

  // const UserScreen = session.host ? HostScreen : AttendeeScreen

  return <SessionContext.Provider value={session}>
    <MediaDeviceContext.Provider value={media}>
      <Box p={1} maxWidth={1280} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <SessionControls style={{ float: 'right' }} />
        <Typography color="textPrimary" variant="h4" display="inline">
          {session.host && <ListItemIcon> <HostIcon /></ListItemIcon>}
          {session.title}
        </Typography>
        {session.date &&
          <Typography variant="subtitle1">Scheduled to begin {session.date_distance}</Typography>
        }
        <OtherStreams />
      </Box >
      <MediaControls />
    </MediaDeviceContext.Provider>
  </SessionContext.Provider>
}