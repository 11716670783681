/* eslint-disable @typescript-eslint/no-unused-vars */
import { Record } from "immutable";
import { useCallback, useEffect, useState } from "react";


export enum MediaStatusCode {
  INIT, NO_PERMISSION, SUCCESS
}

export class MediaState extends Record({
  stream: null as unknown as MediaStream,
  status: MediaStatusCode.INIT,
  trackCount: 0,
  videoEnabled: false,
  audioEnabled: false,
  devices: new Array<MediaDeviceInfo>(),
  device_constraints: { video: { width: 720, deviceId: undefined }, audio: { deviceId: undefined } } as MediaStreamConstraints,
  sinkId: ""
}) {
}

export interface MediaControlFunctions {
  startMedia: () => {},
  stopMedia: () => {},
  stopVideo: () => {},
  startVideo: () => {},
  stopAudio: () => {},
  startAudio: () => {},
  updateStream: () => {},
  setDevice: (d: MediaDeviceInfo) => {}
}

export default function useMediaDevices(): [MediaState, MediaControlFunctions] {
  const [state, setState] = useState(new MediaState({
    // We have to do this otherwise the MediaStream from the Record definition keeps getting used! 🤭
    stream: new MediaStream()
  }))

  const { trackCount } = state

  const setDevice = (d: MediaDeviceInfo) => {
    switch (d.kind) {
      case "audioinput":
        setState(s => s.setIn(['device_constraints', 'audio', 'deviceId'], d.deviceId))
        break;
      case "audiooutput":
        setState(s => s.set('sinkId', d.deviceId))
        break;
      case "videoinput":
        setState(s => s.setIn(['device_constraints', 'video', 'deviceId'], d.deviceId))
        break
    }
    return {}
  }

  const updateStream = useCallback(async () => {
    const dev_stream = await navigator.mediaDevices
      .getUserMedia(state.device_constraints)

    if (!dev_stream) return
    dev_stream.getTracks().forEach(t => {
      t.enabled = false
      state.stream.addTrack(t)
    })

    const devices = await navigator.mediaDevices.enumerateDevices()

    navigator.mediaDevices.ondevicechange = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices()
      setState(s => s.set('devices', devices))
    }

    setState(s => s.withMutations(s2 => {
      s2.set('status', MediaStatusCode.SUCCESS)
      s2.set('trackCount', dev_stream.getTracks().length)
      if (dev_stream.getAudioTracks().length > 0) s2.set('audioEnabled', false)
      if (dev_stream.getVideoTracks().length > 0) s2.set('videoEnabled', false)
      s2.set('devices', devices)
      console.log(dev_stream.getTracks(), devices)
    }))
  }, [state.device_constraints, state.stream])

  useEffect(() => {
    return () => {
      state.stream.getTracks().forEach(t => t.stop())
    }
  }, [state.stream])

  const startMedia = useCallback(async () => {
    if (trackCount !== 0) return
    try {
      setState(s => s.set('status', MediaStatusCode.INIT))
      // navigator.mediaDevices.getUserMedia({video:})
      updateStream()

    } catch (err) {
      console.error(err)
      setState(s => s.set('status', MediaStatusCode.NO_PERMISSION))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stopMedia = useCallback(async () => {
    state.stream.getTracks().forEach(t => {
      t.stop()
      state.stream.removeTrack(t)
    })
    setState(s => s.set('trackCount', state.stream.getTracks().length))
  }, [state.stream])

  const startVideo = useCallback(async () => {
    state.stream.getVideoTracks().forEach(t => t.enabled = true)
    setState(s => s.set('videoEnabled', true))
  }, [state.stream])
  const stopVideo = useCallback(async () => {
    state.stream.getVideoTracks().forEach(t => t.enabled = false)
    setState(s => s.set('videoEnabled', false))
  }, [state.stream])

  const startAudio = useCallback(async () => {
    state.stream.getAudioTracks().forEach(t => t.enabled = true)
    setState(s => s.set('audioEnabled', true))
  }, [state.stream])
  const stopAudio = useCallback(async () => {
    state.stream.getAudioTracks().forEach(t => t.enabled = false)
    setState(s => s.set('audioEnabled', false))
  }, [state.stream])

  return [state, {
    startMedia, stopMedia,
    stopVideo, startVideo, stopAudio, startAudio,
    updateStream, setDevice
  }]
}