// import MobileDetect from 'mobile-detect'

import { AppBar, Box } from "@material-ui/core"

// import { useEffect, useRef } from 'react'
export default function useDeviceSupport() {
  const supported = (navigator.getUserMedia || window.RTCPeerConnection)

  // const md = useRef(new MobileDetect(window.navigator.userAgent)).current
  // const supported = useRef(md.version('iOS')).current
  // console.log(md.mobile());          // 'Sony'
  // console.log(md.phone());           // 'Sony'
  // console.log(md.tablet());          // null
  // console.log(md.userAgent());       // 'Safari'
  // console.log(md.os());              // 'AndroidOS'
  // console.log(md.is('iPhone'));      // false
  // console.log(md.is('bot'));         // false
  // console.log(md.version('Webkit'));         // 534.3
  // console.log("IOS:", md.version('iOS'));       // '4.1.A.0.562'
  // console.log("SAFARI:", md.version('Safari'));       // '4.1.A.0.562'
  // console.log(md.match('playstation|xbox')); // false

  // useEffect(() => {
  //   alert(`iOS: ${md.version('iOS')} | Safari: ${md.version('Safari')} `)
  // })

  return supported
}

export function UnsupportedToolbar() {
  const supported = useDeviceSupport()
  if (!supported) return <AppBar position="static">
    <Box bgcolor="warning.main" textAlign="center">
      smoothe is not supported on this browser
    </Box>

  </AppBar>
  return <></>
}