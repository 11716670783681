import firebase from 'firebase/app'

export const app = firebase.initializeApp({
  apiKey: "AIzaSyA3c3RHKaqpKdkvu5jIt9BWlpVKvoY6le4",
  authDomain: "smoothe.in",
  projectId: "smooth-e",
  storageBucket: "smooth-e",
  messagingSenderId: "718409660642",
  appId: "1:718409660642:web:13251fc1c5757df02286bb",
  measurementId: "G-BHLVCZW36E"
});
