import { FieldPath, FieldValue } from "fb";
import { useEffect } from "react";
import { useUser } from "user_context";
import { useDeviceId } from "util/id";
import { useSession } from "./context";

export default function useDeviceInSession(primary = false) {
  const user = useUser();
  const session = useSession()
  const devId = useDeviceId()

  const devices_fieldpath = new FieldPath('member_devices', user.uid, 'devices')
  const primaryDevicePath = new FieldPath('member_devices', user.uid, 'primaryDevice')

  const mem_devices = session.get_devices(user.uid)

  const isPrimary = mem_devices?.primaryDevice === devId

  function makePrimary() {
    session.doc.ref.update(primaryDevicePath, devId).then(() => {
      window.location.reload()
    })
  }

  useEffect(() => {

    const primaryByDefault = !mem_devices || !mem_devices.primaryDevice
    if (primaryByDefault) {
      session.doc.ref.update(
        devices_fieldpath, FieldValue.arrayUnion(devId),
        primaryDevicePath, devId
      )
    } else {
      session.doc.ref.update(devices_fieldpath, FieldValue.arrayUnion(devId))
    }

    return () => {
      // Use a transaction here to make someone else the primary device
      session.doc.ref.update(new FieldPath('member_devices', user.uid, 'devices'), FieldValue.arrayRemove(devId)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { isPrimary, makePrimary }
}