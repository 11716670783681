import { Fragment } from "react";
import { Box, Grid } from "@material-ui/core";
import { useUser } from "user_context";
import { useSession } from "../context";
import { MemberInfo, MemberRole } from "data/session";
import { MemberStream } from "./member_stream";
import useDeviceInSession from "session/device_presense";
import React from "react";

const OtherStreams = React.memo(() => {
  const user = useUser()
  const session = useSession()
  const { isPrimary } = useDeviceInSession()
  return <Box>

    <Grid container direction="row"
      justify="center"
      alignItems="center"
    >
      {isPrimary || true ?
        [...session.member_info.keys()].map(email => {
          if (email !== user.email) {
            const mem_info = session.member_info.get(email)!
            if (mem_info.role !== MemberRole.REMOVED && mem_info.uid) {
              return <MemberStreams key={mem_info.uid} email={email} userUID={mem_info.uid} />
            }
          }
          return <Fragment key={email}></Fragment>
        }) :
        <><h4>
          This device is sending only
        </h4>
          <NonPrimaryStream />
        </>

      }
    </Grid>
  </Box>
})

export default OtherStreams

function MemberStreams(props: any) {
  const { userUID } = props
  const session = useSession()

  const mem_devices = session.member_devices.get(userUID)!
  if (session.host) {
    return <>
      {mem_devices?.devices?.map(d => <MemberStream
        peerUID={userUID} peerDeviceId={d}
        peerDevices={mem_devices}
        key={d} />)}
    </>
  }

  const primaryDevice = mem_devices.primaryDevice
  return <MemberStream peerUID={userUID} peerDevices={mem_devices} key={primaryDevice} peerDeviceId={primaryDevice} />
}

function NonPrimaryStream() {
  const session = useSession()

  let host_meminfo: MemberInfo | undefined
  session.member_info.forEach((mem_info, email) => {
    if (host_meminfo) return
    // console.log(session)
    // debugger
    if (mem_info.role === MemberRole.HOST) {
      host_meminfo = mem_info
    }
  })

  if (host_meminfo) {
    if (host_meminfo && host_meminfo.uid) {
      const mem_devices = session.get_devices(host_meminfo.uid)

      if (mem_devices?.primaryDevice && mem_devices?.primaryDevice in mem_devices?.devices) return <MemberStream
        peerUID={host_meminfo.uid} peerDeviceId={mem_devices?.primaryDevice}
        peerDevices={mem_devices}
        send_only />
    }
  }

  return <h1>Waiting for Session host's primary device</h1>
}
