import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useState } from "react";

import EditSessionDialog from "./edit_dialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    }
  }),
);


export default function CreateSessions() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  async function createSession() {
    setOpen(true)
  }

  return <>
    <EditSessionDialog open={open} onClose={() => setOpen(false)} />
    <Fab onClick={createSession} variant="extended" className={classes.margin} color="primary" aria-label="New Session"
      size="large">
      <AddIcon className={classes.extendedIcon} /> Create
    </Fab>
  </>
}

