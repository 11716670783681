import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import { auth, login } from 'fb';
import { useUser } from 'user_context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      background: 'none',
      color: theme.palette.secondary.main
    },
    authButton: {
      marginLeft: theme.spacing(1),
      color: 'inherit',
      borderColor: theme.palette.secondary.main
    },
    // menuButton: {
    //   marginRight: theme.spacing(2),
    // },
    title: {
      marginRight: 10,
      flexGrow: 1,
      '& a': {
        color: 'unset',
        textDecoration: 'unset'
      }
    },
  }),
);

export default function TopBar() {
  const user = useUser();
  const classes = useStyles();

  function logout() {
    auth.signOut()
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar variant="dense">
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            <Link to="/">
              smoothe
          </Link>
          </Typography>
          {user ?
            <>
              <span style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
                {user.email}
              </span>
              <Button className={classes.authButton} variant="outlined" onClick={logout}>Logout</Button></>
            :
            <>
              <Typography variant="subtitle1">🙏🏾 Login 👉🏾</Typography>
              <Button className={classes.authButton} variant="outlined" onClick={login}>Login</Button>
            </>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
