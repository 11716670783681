import { formatDistance } from 'date-fns';
import { firebase, firestoreTimestampfromDateString, serverTimestamp } from 'fb'

export enum SessionState {
  CREATED, STARTED, ENDED
}

export enum MemberRole {
  REMOVED, HOST, ATTENDEE
}

export enum RecordingState {
  STOPPED, RECORDING
}

export interface MemberInfo {
  role: MemberRole
  uid: string
}

export interface MemberDevices {
  devices: Array<string>
  primaryDevice: string
}

export default class Session {
  title!: string;
  state: SessionState;
  created!: firebase.firestore.Timestamp;
  date?: firebase.firestore.Timestamp;
  hostId!: string;
  host: boolean;
  member_info: Map<string, MemberInfo>
  member_devices: Map<string, MemberDevices>
  members!: Array<string>;
  doc: firebase.firestore.QueryDocumentSnapshot
  recording: RecordingState

  constructor(doc: firebase.firestore.QueryDocumentSnapshot, user: firebase.User) {
    Object.assign(this, doc.data())
    this.doc = doc
    this.member_info = new Map(Object.entries(doc.data().member_info) || {})
    this.member_devices = new Map(Object.entries(doc.data().member_devices || {}))
    this.host = this.hostId === user.uid
    this.recording = doc.data().recording
    this.state = doc.data().state
  }

  get id() {
    return this.doc.id
  }

  get dict() {
    // This is used for the Update Dialog, so it only needs these fields
    return { title: this.title, date: this.date ? this.date!.toDate() : null }
  }

  static obj_for_new_doc(user: firebase.User, title: string, date?: string) {
    const new_doc = {
      title,
      created: serverTimestamp(),
      hostId: user.uid,
      recording: RecordingState.STOPPED,
      state: SessionState.CREATED,
      member_info: {
        [user.email!]: {
          devices: [],
          role: MemberRole.HOST
        }
      },
      members: [user.email],
      date: firestoreTimestampfromDateString(date)
    } as any

    return new_doc
  }

  get date_distance() {
    return this.date && formatDistance(this.date.toDate(), new Date(), { addSuffix: true })
  }

  get_devices(userUID: string) {
    return this.member_devices.get(userUID)
  }
}