
import { useState } from "react";
import { useSession } from "./context"
import { Email, PauseCircleFilled, People, PersonAdd, RadioButtonChecked } from "@material-ui/icons"
import { makeStyles, Theme, createStyles, Dialog, DialogTitle, Chip, Box, DialogContent, FormControl, IconButton, Input, InputAdornment, InputLabel, ButtonGroup, Button } from "@material-ui/core";
import { useUser } from "user_context";
import { db, FieldPath } from "fb";
import { firebase } from 'fb'
import { MemberRole, RecordingState, SessionState } from "data/session";
import useDeviceInSession from "./device_presense";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    // https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3
    recording: {
      "& .MuiButton-label svg": {
        color: theme.palette.error.main,
        marginRight: '0.15rem'
      }
    },
    blinky: {
      animation: '$blinker 1s linear infinite'
    },
    "@keyframes blinker": {
      "50%": {
        opacity: 0
      }
    }

  }),
);



export default function SessionControls(props: any) {

  const session = useSession()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { isPrimary, makePrimary } = useDeviceInSession()
  const [updating, setUpdating] = useState(false)
  async function updateRecordingState() {
    // if (!window.confirm(`This will ${session.recording === RecordingState.STOPPED ? 'START' : 'STOP'} recording on all devices, are you sure?`)) {
    //   return
    // }
    setUpdating(true)

    const new_state_calc = (state: RecordingState) => {
      switch (state) {
        case RecordingState.STOPPED:
          return RecordingState.RECORDING
        case RecordingState.RECORDING:
          return RecordingState.STOPPED
      }
    }

    const new_state = new_state_calc(session.recording)
    await session.doc.ref.update({ recording: new_state })
    setUpdating(false)
  }

  return <div {...props}>
    <ButtonGroup color="primary">
      {session.recording === RecordingState.RECORDING &&
        <Button className={classes.recording} variant="text" disabled>
          <RadioButtonChecked className={classes.blinky} />RECORDING
          </Button>
      }
      {!isPrimary && <Button onClick={makePrimary}>
        Make Primary Device
      </Button>
      }
      {session.host && <Button disabled={updating || session.state === SessionState.ENDED}
        className={classes.recording} onClick={() => updateRecordingState()}>
        {session.recording === RecordingState.STOPPED && <><RadioButtonChecked />Start Recording</>}
        {session.recording === RecordingState.RECORDING && <><PauseCircleFilled />Stop Recording</>}
      </Button>}

      <Button onClick={() => setOpen(true)}>
        <People className={classes.extendedIcon} /> {session.members.length} Attendees
        </Button>
    </ButtonGroup>
    <AttendeesDialog onClose={() => setOpen(false)} open={open} />
  </div>
}


function AttendeesDialog(props: any) {
  const session = useSession()
  const user = useUser()
  const [new_email, setNewEmail] = useState('')

  const sessionDoc = db.collection("sessions").doc(session.id)

  async function handleDelete(email: string) {
    await sessionDoc.update(
      new FieldPath('member_info', email), { role: MemberRole.REMOVED },
      'members', firebase.firestore.FieldValue.arrayRemove(email))
  }

  async function handleAdd() {
    const _new_email = new_email.toLowerCase().trim()

    if (session.members.includes(_new_email)) {
      alert(`${_new_email} is already invited`)
      setNewEmail('')
    } else if (_new_email === user.email?.toLowerCase().trim()) {
      alert("You're trying to add yourself 🤦🏽‍♂️")
      setNewEmail('')
    }

    if (_new_email === '') return

    await sessionDoc.update(
      new FieldPath('member_info', _new_email), { role: MemberRole.ATTENDEE },
      'members', firebase.firestore.FieldValue.arrayUnion(_new_email)
    )
    setNewEmail('')
  }

  const email_err = !Yup.string().email().isValidSync(new_email)
  return (
    <Dialog {...props}>
      <DialogTitle id="simple-dialog-title">Attendees</DialogTitle>
      <DialogContent style={{ padding: '1em' }}>

        <Box p={1}>
          {session.members.map((email: string) =>
            <Box key={email} m={1}><Chip
              label={email} onDelete={session.host && email !== user.email ? () => handleDelete(email) : undefined} />
            </Box>
          )}
        </Box>

        {session.host &&
          // <DialogActions>
          <form onSubmit={e => handleAdd() && e.preventDefault()}>
            <FormControl fullWidth>
              <InputLabel htmlFor="new-attendee">Add Attendee</InputLabel>
              <Input
                fullWidth
                autoFocus
                id="new-attendee"
                type='email'
                value={new_email}
                error={email_err}
                onChange={e => setNewEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Add attendee"
                      // onClick={handleAdd}
                      type="submit"
                      onMouseDown={handleAdd}
                      disabled={email_err}
                    >
                      <PersonAdd />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        }

      </DialogContent>
    </Dialog>
  );
}