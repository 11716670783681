import Session from "data/session"
import { configuration } from "./config"
import firebase from 'firebase/app'
import { uuid } from "util/id"

interface SMPeerConnectionProps {
  localStream: MediaStream
  session: Session
  myUID: string
  myDeviceId: string
  peerUID: string
  peerDeviceId: string
  send_only: boolean
  rec_only: boolean
  timestamp: Function
  stateSetter: Function
}

const assert_equals = (a: any, b: any, msg: any) => a === b || void fail(new Error(`${msg} expected ${b} but got ${a}`))
const fail = (e: any) => { console.log({ error: `${e.name}: ${e.message}` }) };

export default class SMPeerConnection {
  localStream: MediaStream
  session: Session
  myDeviceId: string
  peerDeviceId: string
  pairKey: string
  connUID: string = ''
  pc!: RTCPeerConnection // We call newPC in constructor
  remoteStream!: MediaStream

  offeringDevice?: string

  collRef: firebase.firestore.CollectionReference
  offRef: firebase.firestore.DocumentReference
  iceDocRef: firebase.firestore.DocumentReference
  peerDescDocRef: firebase.firestore.DocumentReference
  myDescDocRef: firebase.firestore.DocumentReference

  makingOffer: boolean = false
  srdAnswerPending: boolean = false
  isSettingRemoteAnswerPending: boolean = false

  snapshotsToClose = new Array<Function>()

  myId: string
  destroyed = false

  timestamp: Function
  video?: HTMLVideoElement
  send_only: boolean
  rec_only: boolean

  stateSetter: Function
  desc_processed!: Array<string>
  polite: boolean

  constructor({ localStream,
    session,
    myUID,
    myDeviceId,
    peerUID,
    peerDeviceId,
    send_only,
    rec_only,
    timestamp,
    stateSetter
  }: SMPeerConnectionProps) {

    this.myId = uuid().substr(0, 5)
    this.timestamp = () => Date.now().toString().substring(7, 11)
    console.log(this.timestamp(), "Constructing SMP", this.myId)

    this.localStream = localStream
    this.session = session
    this.myDeviceId = myDeviceId
    this.peerDeviceId = peerDeviceId
    this.pairKey = [myDeviceId, peerDeviceId].sort().join('+')
    this.polite = peerDeviceId > myDeviceId
    this.send_only = send_only
    this.rec_only = rec_only
    this.stateSetter = stateSetter

    this.collRef = session.doc.ref.collection(([...[myUID, peerUID].sort(), this.pairKey]).join("/"))
    this.offRef = this.collRef.doc('!offering')
    this.iceDocRef = this.collRef.doc('ice_candidates')
    this.peerDescDocRef = this.collRef.doc(`description_${peerDeviceId}`)
    this.myDescDocRef = this.collRef.doc(`description_${myDeviceId}`)

    this.newPC('Constructor')

    this.snapshotsToClose.push(
      this.iceDocRef.collection(peerDeviceId).onSnapshot(this.onIceCandidateSnapshot.bind(this)),
      this.peerDescDocRef.onSnapshot(this.onDescriptionSnapshot.bind(this)),
    )

  }

  destroy() {
    this.snapshotsToClose.forEach(closeFunc => closeFunc())
    this.pc?.close()
  }

  newPC(because = '', force = true) {
    if (this.pc) this.pc.close()
    this.pc = new RTCPeerConnection(configuration)
    this.myId = uuid().substr(0, 5)
    console.log(this.timestamp(), `BRAND NEW PC!!!! `, because, this.pc && this.pc.connectionState, `: ${this.connUID} (${this.myId})`)
    window['pc' as any] = this.pc as any

    this.remoteStream = new MediaStream()
    this.desc_processed = []

    this.pc.onicecandidate = this.onicecandidate.bind(this)
    this.pc.onnegotiationneeded = this.onnegotiationneeded.bind(this)

    this.registerPeerConnectionListeners(this.pc)

    this.pc.ontrack = ev => {
      if (this.send_only) {
        console.log("AYO WHY YOU SEND ME TRACKS YO! STAAAAH", ev)
      }

      ev.streams.forEach(stream => {

        stream.getTracks().forEach(track => {
          track.onunmute = ev => {
            console.log(track.id, '***UNMUTE***', ev, track.getSettings())
            this.remoteStream.getTracks().forEach(t => {
              if (t.kind === track.kind && t.id !== track.id)
                this.remoteStream.removeTrack(t)
            })
          }

          if (this.remoteStream.getTrackById(track.id)) return
          console.log("Adding track to RemoteStream", track.kind, track.id, track.readyState, this.myId)
          this.remoteStream.addTrack(track)
        })
      })
      if (this.video) {
        this.video.srcObject = this.remoteStream
      }
    }

    this.updateLocalTracks()
    if (this.localStream.getTracks().length > 0) {
      this.onnegotiationneeded()
    }
    this.logState()
  }

  updateLocalTracks(stream?: MediaStream) {
    if (stream) {
      this.localStream = stream
    }

    if (this.rec_only) {
      return console.log("We don't send tracks to this guy")
    }
    const tracks = this.localStream.getTracks()
    if (tracks.length === 0) return
    if (tracks.length !== 2) debugger

    tracks.forEach(track => {

      let found_kind = false
      this.pc.getSenders().forEach(sender => {
        if (track.kind === sender.track?.kind) {
          found_kind = true
          if (track.id !== sender.track?.id) {
            sender.replaceTrack(track)
          }
        }
      })
      if (!found_kind) this.pc.addTrack(track, this.localStream)
    })
  }

  setVideoElem(el: HTMLVideoElement) {
    this.video = el
  }

  updateStateSetter(fn: Function) {
    this.stateSetter = fn
  }

  logState() {
    if (this.stateSetter) {
      this.stateSetter(`${this.pc.connectionState}/${this.pc.signalingState}`)
    } else {
      console.warn("no state setter")
    }
  }

  registerPeerConnectionListeners(pc: RTCPeerConnection) {
    pc.addEventListener('icegatheringstatechange', () => {
    });

    pc.addEventListener('connectionstatechange', () => {
      console.log(this.timestamp(), `Connection state change: ${pc.connectionState}: ${this.connUID} (${this.myId})`);

      this.logState()

      switch (pc.connectionState) {
        case "connected":
          if (this.video) {
            this.video.srcObject = this.remoteStream
          }
          break
        case "connecting":
          break;
        case "disconnected":
          setTimeout(this.connectingTimeout.bind(this), 500)
          break;
        case "failed":
          break;
        // this.newPC('WE FAILED YO')
        // this.onnegotiationneeded()
      }
    });

    pc.addEventListener('signalingstatechange', () => {
      console.log(this.timestamp(), `Signaling state change: ${pc.signalingState}: ${this.connUID} (${this.myId})`);
      this.logState()
    });

    pc.addEventListener('iceconnectionstatechange ', () => {
      console.log(this.timestamp(),
        `ICE connection state change: ${pc.iceConnectionState}: ${this.connUID} (${this.myId})`);
    });
  }

  connectingTimeout() {
    console.log(this.timestamp(), `connectingTimeout: ${this.pc?.connectionState}`)
    if (this.pc?.connectionState !== "connected") {
      this.newPC('disconnect timeout')
    }
  }

  async onnegotiationneeded(EventOrOptions?: any) {
    try {
      console.log('SLD due to negotiationneeded');
      assert_equals(this.pc.signalingState, 'stable', 'negotiationneeded always fires in stable state');
      assert_equals(this.makingOffer, false, 'negotiationneeded not already in progress');
      this.makingOffer = true;
      const offer = await this.pc.createOffer()
      await this.pc.setLocalDescription(offer);
      assert_equals(this.pc.signalingState, 'have-local-offer', 'negotiationneeded not racing with onmessage');
      assert_equals(this.pc.localDescription?.type, 'offer', 'negotiationneeded SLD worked');
      await this.sendDescription()
      setTimeout(this.sent_offer_timeout.bind(this), 1000)
    } catch (e) {
      fail(e);
    } finally {
      this.makingOffer = false;
    }
  }

  sent_offer_timeout() {
    if (this.pc.signalingState === 'have-local-offer') {
      console.log("Weird, let's try sending offer again")
      this.sendDescription()
    }
  }

  onicecandidate(event: RTCPeerConnectionIceEvent) {
    const myICEDocRef = this.iceDocRef.collection(this.myDeviceId)
    if (event.candidate) myICEDocRef.add(event.candidate.toJSON())
  }

  onIceCandidateSnapshot(qsnap: firebase.firestore.QuerySnapshot) {
    qsnap.docChanges().forEach(async change => {
      if (change.type === "added") {
        const candidate = change.doc.data()
        try {
          await this.pc.addIceCandidate(new RTCIceCandidate(candidate))
        } catch (err) {
          // console.log("error on adding ice candidate", candidate)
          // c'est la vie
        } finally {
          change.doc.ref.delete()
        }
      }
    })
  }

  async sendDescription() {
    const desc_id = uuid().substr(0, 5)
    console.log(this.timestamp(), 'SEND Description', this.pc.localDescription && this.pc.localDescription.type, desc_id)
    if (!this.pc.localDescription) return

    return await this.collRef.doc(`description_${this.myDeviceId}`).set({
      description: this.pc.localDescription?.toJSON(),
      desc_id
    })
  }

  async onChannelMessage(event: any) {
    console.log("onChannelMessage", event, event.data)
  }

  async clearDescription() {
    return await this.myDescDocRef.delete()
  }

  async clearPeerDescription() {
    return await this.peerDescDocRef.delete()
  }

  async unstuck_myself() {
    console.log('unstuck_myself')
    this.peerDescDocRef.get().then(this.onDescriptionSnapshot.bind(this))
  }

  async onDescriptionSnapshot(snap: firebase.firestore.DocumentSnapshot): Promise<any> {
    if (!snap.exists) return

    const desc = (snap.exists && snap.data()!['description']) || {}
    const { desc_id } = snap.data()!
    if (this.desc_processed.includes(desc_id)) return
    this.desc_processed.push(desc_id)
    console.log(this.timestamp(), 'GOT Description', desc['type'], desc_id)
    const pc = this.pc
    const description = snap.data()!['description']

    try {
      // If we have a setRemoteDescription() answer operation pending, then
      // we will be "stable" by the time the next setRemoteDescription() is
      // executed, so we count this being stable when deciding whether to
      // ignore the offer.
      const isStable =
        this.pc.signalingState === 'stable' ||
        (this.pc.signalingState === 'have-local-offer' && this.srdAnswerPending);
      const ignoreOffer =
        description.type === 'offer' && !this.polite && (this.makingOffer || !isStable);
      if (ignoreOffer) {
        console.log('glare - ignoring offer');
        return;
      }
      this.srdAnswerPending = description.type === 'answer';
      console.log(`SRD(${description.type})`);

      try {
        await pc.setRemoteDescription(description);
      } catch {
        if (description.type === "answer") {
          return setTimeout(this.onnegotiationneeded.bind(this), 500)
        }
      }

      this.srdAnswerPending = false;
      if (description.type === 'offer') {
        assert_equals(pc.signalingState, 'have-remote-offer', 'Remote offer');
        assert_equals(pc.remoteDescription?.type, 'offer', 'SRD worked');
        console.log('SLD to get back to stable');
        const answer = await pc.createAnswer()
        await pc.setLocalDescription(answer);
        assert_equals(pc.signalingState, 'stable', 'onmessage not racing with negotiationneeded');
        assert_equals(pc.localDescription?.type, 'answer', 'onmessage SLD worked');
        await this.sendDescription()
      } else {
        assert_equals(pc.remoteDescription?.type, 'answer', 'Answer was set');
        assert_equals(pc.signalingState, 'stable', 'answered');
        pc.dispatchEvent(new Event('negotiated'));
      }

    } catch (err) {
      console.error(err)
      this.newPC('errord!!')
    }
    finally {
      this.clearPeerDescription()
    }

  }
}

const smpconnections = new Map<string, SMPeerConnection>()
window['s' as any] = smpconnections as any

export function getSMPC(props: SMPeerConnectionProps) {
  const { session, myDeviceId, peerDeviceId } = props
  const smpcKey = `${session.id}+${myDeviceId}+${peerDeviceId}`
  if (smpconnections.has(smpcKey)) {
    const _smpc = smpconnections.get(peerDeviceId)!
    return { smpcKey, SMPC: _smpc }
  }

  const SMPC = new SMPeerConnection(props)
  smpconnections.set(smpcKey, SMPC)
  return { smpcKey, SMPC }
}

export function getSMPCByKey(smpcKey: string) {
  return smpconnections.get(smpcKey)
}

export function destroySMPC(smpcKey: string) {
  const SMPC = smpconnections.get(smpcKey)
  if (SMPC) {
    SMPC.destroy()
    smpconnections.delete(smpcKey)
  }
}