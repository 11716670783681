import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup } from "@material-ui/core";

import { InputField } from "components/formik_material_inputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { db, firestoreTimestampfromDateString } from "fb";
import { useUser } from "user_context";
import Session from "data/session";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
// import { KeyboardDateTimePicker } from "formik-material-ui-pickers";

export default function EditSessionDialog(props: any) {
  const user = useUser();
  const { update, ...rest } = props

  async function onCreate(values: any) {
    try {
      const { title, date } = values
      const new_doc = Session.obj_for_new_doc(user, title, date)


      await db.collection("sessions").add(new_doc)
    } catch (error) {
      alert("SORRY ERROR")
      console.error(error)
    }
    (props as any).onClose();
  }


  async function onUpdate(values: any) {
    try {
      const { title, date } = values
      const doc = {
        title,
        date: firestoreTimestampfromDateString(date)
      } as any


      await db.collection("sessions").doc(update.id).update(doc)
    } catch (error) {
      alert("SORRY ERROR")
      console.error(error)
    }
    (props as any).onClose();
  }


  async function onDelete() {
    if (window.confirm(`Are you sure you want to delete session "${update.title}"?`)) {
      await db.collection("sessions").doc(update.id).delete()
    }
  }

  const { title,
    date
  } = formFields

  return (
    <Dialog {...rest}>
      <DialogTitle >{update ? 'Update' : 'Create a New'} Session</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <Formik
            initialValues={update ? update.dict : { title: "", date: null }}
            validationSchema={validationScheme}
            onSubmit={update ? onUpdate : onCreate}
          >
            {({ isSubmitting, values, setFieldValue }) =>
              <Form>
                <FormGroup>
                  <InputField
                    margin="dense"
                    fullWidth
                    autoFocus
                    {...title}
                  />
                  <KeyboardDateTimePicker
                    {...date}
                    value={values.date}
                    onChange={value => setFieldValue("date", value)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </FormGroup>
                <DialogActions >
                  {update &&
                    <Button
                      // variant="outlined"
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={onDelete}
                    >
                      Delete
                </Button>}
                  <Button
                    // variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {update ? 'Update' : 'Create'}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{ position: "absolute" }}
                      />
                    )}
                  </Button>
                </DialogActions>

              </Form>
            }
          </Formik>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
}

const formFields = {
  title: {
    name: "title",
    label: "Title",
    test: Yup.string()
      .required("Please title your session"),
  },

  date: {
    id: 'session-date',
    name: "date",
    label: "Date (optional)",
    helperText: 'When does this session start?',
    clearable: true,
    test: Yup.date().nullable().min(new Date(), "Date must be in the future")
  },
};

export function validationScheme() {
  return Yup.object().shape(
    Object.entries(formFields).reduce((acc: any, v) => {
      acc[v[0]] = v[1].test
      return acc;
    }, {})
  );
}