import { auth } from "fb";
import { UserContext } from "user_context";
import { useAuthState } from "react-firebase-hooks/auth";
import { Router } from "@reach/router"
import TopBar from 'components/TopBar';
import undraw from 'undraw/undraw_video_call_kxyp.svg'
import SessionView from 'session'
import SesssionList from 'session/list'
import { CssBaseline, LinearProgress, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { Performance, PerformanceContext } from "performance_context";

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { useMemo } from "react";
import { blue } from "@material-ui/core/colors";
import { RecorderContext } from "recorder/context";
import Recorder from "recorder";
import { UnsupportedToolbar } from "components/device_support";

function App() {
  const [user, loading] = useAuthState(auth);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    defaultMatches: window.matchMedia('(prefers-color-scheme: dark)').matches
  });

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: blue,
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  if (loading) return <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* <TopBar /> */}
    <LinearProgress />
  </ThemeProvider>


  return <ThemeProvider theme={theme}><UserContext.Provider value={user}>
    <CssBaseline />
    <PerformanceContext.Provider value={new Performance()}>
      <UnsupportedToolbar />
      <TopBar />
      {user ?
        <RecorderContext.Provider value={new Recorder()}>
          <Router>
            <SesssionList path="/" />
            <SessionView path="/j/:sessionId" />
          </Router>
        </RecorderContext.Provider>

        :
        <>
          <img style={{
            position: 'fixed',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }} src={undraw} alt="Smooth video calls" />
        </>
      }
    </PerformanceContext.Provider>
  </UserContext.Provider>
  </ThemeProvider>
}

export default App;
